import qs from 'query-string'

class ManagerLoginPage extends Component {
  state = {}

  render() {
    const {email, password, error} = this.state;
    const tabinfo = this.fetchTabInfo();
    return (
      <layouts.partials.SiteContent>
        <Card stretch>
          {error && <Notice type="error">{error}</Notice>}
          <LabeledInput label="E-mail">
            <TextInput value={email || ''} onChange={v => this.setState({ email: v })} type='email' />
          </LabeledInput>
          <LabeledInput label="Пароль">
            <TextInput value={password || ''} onChange={v => this.setState({ password: v })} type='password' />
          </LabeledInput>
          <Margin size="30 0" />
          <SplitSides>
            <Button onClick={() => this.authenticate()}>Войти</Button>
            <a href={this.insalesAuthUrl()}>Войти через inSales</a>
          </SplitSides>
          {tabinfo &&
            <p>
              Если вас выбросило:<br/>
              <code>{tabinfo}</code>
              &nbsp;
              <Button onClick={()=> navigator.clipboard.writeText(tabinfo)} small color="gray" icon="content_copy" />
            </p>
          }
        </Card>
      </layouts.partials.SiteContent>
    );
  }

  insalesAuthUrl() {
    let result = '/staff/login?insales=true';
    const redirectUrl = qs.parse(this.props.location.search).redirect;
    if(redirectUrl)
      result += `&redirect=${encodeURIComponent(redirectUrl)}`;
    return result;
  }

  authenticate() {
    const {email, password} = this.state;
    const setError = msg => this.setState({ error: msg || 'Прозошла неизвестная ошибка' });
    const onError = data => setError(data.responseJSON && data.responseJSON.errors.join('; '));

    API.sessions.create({
      params: { type: 'manager', email: email, password: password },
      success: user => browserHistory.push('/staff'),
      error: onError
    });
  }

  fetchTabInfo() {
    const meta = document.querySelector("meta[name='tabinfo']")
    if(!meta)
      return;
    return `${(new Date()).toISOString()} ${meta.content}`;
  }
}

export default ManagerLoginPage;
