import { actions } from './actions'
import { store } from 'app/store'
import repositories from 'app/models/repositories'

const onError = errorTag => (data => console.log(errorTag, data));
const d = store.dispatch;

/*
 * Commonly used store interactions
 */

const currentUserRepo = new repositories.CurrentUserRepo();
const reloadCurrentUser = () => currentUserRepo.get()
                                               .then(u => d(actions.setCurrentUser(u)), onError('reloadCurrentUser'))

const groupRepo = new repositories.GroupRepo();
const reloadGroups = () => groupRepo.getAll()
                                    .then(t => d(actions.setGroups(t)), onError('reloadGroups'));
const tagRepo = new repositories.TagRepo();
const reloadTags = () => tagRepo.getAll()
                                .then(t => d(actions.setTags(t)), onError('reloadTags'));
const categoryRepo = new repositories.CategoryRepo();
const reloadCategories = () => categoryRepo.getAll()
                                     .then(t => d(actions.setCategories(t)), onError('reloadCategories'));

const scriptRepo = new repositories.ScriptRepo();
const reloadScripts = () => scriptRepo.getAll()
                                      .then( s => d(actions.setScripts(s)), onError('reloadScripts'))
const answerTemplateRepo = new repositories.AnswerTemplateRepo();
const reloadAnswerTemplates = () => answerTemplateRepo.getAll()
                                                      .then( at => d(actions.setAnswerTemplates(at)), onError('reloadAnswerTemplates'))

const managerRepo = new repositories.ManagerRepo();
const reloadManagers = () => managerRepo.getAll()
                                        .then(m => d(actions.setManagers(m)), onError('reloadManagers'));

const chatGPTModelRepo = new repositories.ChatGPTModelRepo();
const reloadChatGPTModels = () => chatGPTModelRepo.getAll()
                                                  .then( at => d(actions.setChatGPTModels(at)), onError('reloadChatGPTModels'))

const userRepo = new repositories.UserRepo();
const loadUser = id => userRepo.get(id)
                               .then(u => d(actions.setUser(u)),
                                     data => { console.log(data); d(actions.setUser(null, id)) });
const loadUserBunch = ids => userRepo.getAll({ ids })
                                     .then(function(data) {
                                       console.log(data);
                                       const {users} = data;
                                       const res = users.reduce((a, u) => Object.assign(a, { [u.id]: u }), {});
                                       d(actions.mergeUsersById(res));
                                     });

const setDocumentTitle = title => d(actions.setDocumentTitle(title));
const setDefaultDocumentTitle = () => setDocumentTitle('Тех. поддержка inSales');

const setHideEventsInFeed = value => d(actions.setHideEventsInFeed(value));
const setReverseEventFeed = value => d(actions.setReverseEventFeed(value));
const setMessageFormCache = (id, text) => d(actions.setMessageFormCache(id, text));
const setAppbarSearchType = value => d(actions.setAppbarSearchType(value))
const setPreferPersonalAnswerTemplates = value => d(actions.setPreferPersonalAnswerTemplates(value))
const setPreferPersonalScripts = value => d(actions.setPreferPersonalScripts(value))
const setTicketStatusFilter = status => d(actions.setTicketStatusFilter(status));

export { reloadCurrentUser, reloadGroups, reloadTags, reloadManagers, setDefaultDocumentTitle, setDocumentTitle, reloadCategories};
export { reloadAnswerTemplates, reloadChatGPTModels };
export { reloadScripts, setAppbarSearchType, setTicketStatusFilter, loadUser, loadUserBunch }
export { setReverseEventFeed, setHideEventsInFeed, setMessageFormCache };
export { setPreferPersonalAnswerTemplates, setPreferPersonalScripts };
