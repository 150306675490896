import { createStore } from 'redux'
import { actions, actionTypes } from './actions'
import {objectWithoutNulls} from 'app/lib/utils'
import {localCache, withWritingToLocalCache} from './local_cache'
import {userSettingsReducer, initialUserSettings} from './user_settings'

const initialState = {
  currentUser: {},
  groups: [],
  tags: [],
  categories: [],
  managers: [],
  activeManagers: [],
  scripts: [],
  answerTemplates: [],
  documentTitle: 'Тех. поддержка inSales',
  messageFormCache: localCache.values().messageFormCache || {},
  usersById: {},
  userSettings: initialUserSettings,
  chatGPTModels: [],
}

/*
 * reducers.
 * NOTE: They are different from recommended redux way.
 *       Instead of specific property of the state they expect
 *       to recieve the full state. Therefore, they can't be combined via `combineReducers()`.
 */

/*
 * Stored data from backend like managers and tags
 */
function dataReducer(state, action) {
  switch(action.type) {
    case actionTypes.setCurrentUser:
      return Object.assign({}, state, { currentUser: action.user });
    case actionTypes.setGroups:
      return Object.assign({}, state, { groups: action.groups });
    case actionTypes.setTags:
      return Object.assign({}, state, { tags: action.tags });
    case actionTypes.setCategories:
      return Object.assign({}, state, { categories: action.categories });
    case actionTypes.setManagers: {
      const activeManagers = action.managers.filter(m => !m.inactive);
      return Object.assign({}, state, { managers: action.managers, activeManagers });
    }
    case actionTypes.mergeUsersById: {
      const merged = Object.assign({}, state.usersById, action.usersObject);
      return Object.assign({}, state, { usersById: merged });
    }
    case actionTypes.setUser: {
      const nextUsersById = {...state.usersById};
      const id = action.id || action.user.id;
      nextUsersById[id] = action.user;
      return Object.assign({}, state, { usersById: nextUsersById });
    }
    case actionTypes.setScripts:
      return Object.assign({}, state, { scripts: action.scripts });
    case actionTypes.setAnswerTemplates:
      return Object.assign({}, state, { answerTemplates: action.answerTemplates });
    case actionTypes.setChatGPTModels:
      return Object.assign({}, state, { chatGPTModels: action.chatGPTModels });
    default:
      return state;
  }
}

function reducer(state = initialState, action) {
  state = Object.assign(
    {},
    dataReducer(state, action),
    { userSettings: userSettingsReducer(state.userSettings, action) },
  );

  switch(action.type) {
    case actionTypes.setDocumentTitle:
      return Object.assign({}, state, { documentTitle: action.documentTitle });
    case actionTypes.setMessageFormCache: {
      const updatedCache = Object.assign({}, state.messageFormCache, { [action.id]: action.text || null });
      return withWritingToLocalCache(state, { messageFormCache: objectWithoutNulls(updatedCache) });
    }
    default:
      return state;
  }
}

const store = createStore(reducer);

export { store, actions as storeActions };
